

import * as React from "react";
import * as ReactDOM from "react-dom";




function Footer() {
  




  return (
    <footer style={{ color:'black', padding:'15px'}}>
        <div className="container">
            <div className="row">
                {/* <div className="col">
                <a href="https://twitter.com/Mintstarter" target="_blank" style={{color:'grey', textDecoration:'none'}}>  <img src="/twitter.svg" width="100%" style={{maxWidth:'40px'}} alt="telegram" /> </a>
                <a href="https://t.me/mintstarter" target="_blank" style={{color:'grey', textDecoration:'none'}}>    <img src="/telegram.svg" width="100%" style={{maxWidth:'40px'}}alt="telegram" /> </a>
                <a href="https://www.dextools.io/app/ether/pair-explorer/0xa23ad2b0fd9cf4f2a056dc249d95777a6e3b21ac" target="_blank" style={{color:'grey', textDecoration:'none'}}>    <img src="/dextools.png" width="100%" style={{maxWidth:'40px'}}alt="telegram" /> </a>

                </div> */}
                <div className="col-12 col-md-6">
                    <h2 style={{fontWeight:'bold', textAlign:'center'}}>BlockSwap</h2>
                    {/* <img src="/bswaplogo.png" style={{width:'100%', maxWidth:"256px"}} alt="logo" /> */}
                    <p style={{textAlign:'center'}}>
                    A congregation of automated technology tied together by our swap and token.
                    </p>
                    <p style={{textAlign:'center'}}>
                    <a href="/terms" style={{color:'black', textDecoration:'none'}}>Terms and Conditions</a>
                    </p>
                    <p style={{textAlign:'center'}}>
                    <a href="/privacy" style={{color:'black', textDecoration:'none'}}>Privacy Policy</a>
                    </p>

                </div>

                <div className="col-12 col-md-6" style={{textAlign:'right'}}>
                <h4 style={{textAlign:'center', marginTop:"3%"}}>Community </h4>
                    <div className="row" style={{marginTop:'3%'}}>
                    <div className="col-12" style={{textAlign:'center'}}>
                        <a href="https://t.me/OfficialBlockswap" target="_blank" rel="noreferrer">
                            <img src="/tgram.png" width="100%" style={{maxWidth:'44px', marginRight:'2px'}}  />   
                        </a>                         
                        <img src="/tgram2.png" width="100%" style={{maxWidth:'44px', marginRight:'2px'}}  />      
                        <a href="https://medium.com/@OfficialBlockswap" target="_blank" rel="noreferrer">
                            <img src="/tgram3.png" width="100%" style={{maxWidth:'44px', marginRight:'2px'}}  />       
                        </a>  
                        <a href="https://Twitter.com/@RealBlockSwap" target="_blank" rel="noreferrer">
                            <img src="/tgram4.png" width="100%" style={{maxWidth:'44px', marginRight:'2px'}}  />      
                        </a>  
                        </div>
                        <div className="col-12" style={{marginTop:'5%', textAlign:'center'}}>
                    <a href="/" style={{color:'black', textDecoration:'none', textAlign:'center'}}><small>&copy; Copyright 2022, BlockSwap</small></a>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    </footer>


  ); 
}

export default Footer;