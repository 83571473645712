import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";
import { ToastContainer, toast } from 'react-toastify';
import { ethers } from 'ethers'
import 'react-toastify/dist/ReactToastify.css';
import {
  injected,
  walletconnect,
} from "../connectors";
import { useEagerConnect, useInactiveListener } from "../hooks";
import Web3 from 'web3'
import Navbar from '../components/Navbar'
import TopMarketCap from '../components/TopMarketCap'
import VisitStaking from '../components/VisitStaking'
import TopNFTs from '../components/TopNFTS'
import RecentlyCreated from '../components/RecentlyCreated'
import AnyOwnedByUser from "../components/AnyOwnedByUser";
import Price from '../components/Price'
import MintstarterServices from '../components/MintstarterServices'
import Footer from '../components/Footer'
import '../App.css'

const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect
};

function getErrorMessage(error) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}



function CustomSwap() {


  return (
    <div>
      <Navbar />
      <div className="container" style={{minHeight:'1100px'}}>
        <br /> 
        <div>

          <div className="row">
            
            <div className="col-12 col-md-12">
              <h4 style={{color:'white', textAlign:'center'}}> $1500 by BNB </h4>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSecEfDcSVXtbzk9Eu1obGGTOwU-nRutY6QDWSEAoqO5bin9DQ/viewform?embedded=true" width="100%" height="2440" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
          </div>

        </div>
      </div>
      <Footer />
   </div>

  ); 
}

export default CustomSwap;