

import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    useWeb3React,
  } from "@web3-react/core";
import Web3 from 'web3'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import ABIPolygon from '../utils/abi_polygon.json'
import BYTECODEPolygon from '../utils/bytecode_polygon.json'
import ABIEth from '../utils/abi_eth.json'
import BYTECODEEth from '../utils/bytecode_eth.json'
import ABIBoth from '../utils/abi_both.json'
import BYTECODEBoth from '../utils/bytecode_both.json'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';

const auth = 'Basic ' + Buffer.from('271a2F9WoNKWxEw8NNtmMkviS4x' + ':' + 'ecfad3264edefc0e18f1ba349cb95796').toString('base64')

const ipfs = ipfsHttpClient({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  apiPath:'/api/v0',
  headers: {
    authorization: auth
    }
})


function LaunchComponent() {
  


  const context = useWeb3React();
  const {
    library,
    chainId,
    account,
    active,
  } = context;






  return (
    <div className="row" style={{marginTop:'10%', marginBottom:'5%'}}>
        <div className="card" style={{width:"100%", color:'black', borderRadius:'22px', boxShadow:'0 5.40728px 10.8146px rgba(0,0,0,.3)'}}>
          <div className="card-header" style={{background:'#4742FF', borderRadius:'22px 22px 0px 0px'}}>
          <h5 style={{textAlign:'left', fontWeight:'bold', color:'white'}}>
                Create Staking Pools
                </h5>
          </div>            <div className="card-body">
          <p style={{textAlign:'center', color:'black', marginLeft:'10%', marginRight:'10%', fontSize:'20px'}}>
                Do you have a token, actively trading and want to provide staking to your community? Start now and empower your community with unique DeFi solutions.
                NO CODE REQUIRED
                </p>
                <h5 style={{textAlign:'center', marginTop:"5%", fontSize:'20px'}}>
                Choose what is right for you
                </h5>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <a href="/entry-staking" style={{textDecoration:'none'}}>
                      <img src="/New Project (4).svg" width="100%" alt="entry staking" />
                    </a>
                  </div>
                  <div className="col-12 col-md-6">
                  <a href="/pro-staking" style={{textDecoration:'none'}}>
                    <img src="/New Project (6).svg" width="100%" alt="entry staking" />

                  </a>
                  </div>
                </div>
                <div>
                    {/* {isdeployed ? <div class="alert alert-primary" style={{marginTop:'3%'}} role="alert">
                        <p>Your token has been deployed at <span style={{fontWeight:'bold'}}>{tokenaddress}</span> . Save this address for reference purposes.</p>
                         </div> : null} */}
                </div>

        
            </div>        

        </div>

    {/* TOAST AREA */}
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
    </div>


  ); 
}

export default LaunchComponent;