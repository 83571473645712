

import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactTypingEffect from 'react-typing-effect';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function MintstarterServices() {
  

  


  return (
    <div style={{padding:'15px'}}>
                       {/* <iframe scrolling="no" height="500" width="350" style={{width:"350px",height:"500px", border: "none", borderRadius: "19px", boxShadow: "rgba(0, 0, 0, 0.1) 3px 3px 10px 4px", display: "block", margin:"0 auto"}} src="https://blockswapdex.netlify.app/swap?inputCurrency=ETH&outputCurrency=0x4636b326f19ca9b8a4c98f91672a31c617f249d3&slippage=1200&dark=dark" />       */}

        <div className="row" style={{marginTop:'8%'}}>

            <div className="col-12">
                <h5 style={{fontWeight:'bold'}}>
                BlockSwap is your personal development suite. Use our smart compilers to instantly generate customized Solidity contracts for Staking Pools, Exchange forks or Tokens. No coding required.
                </h5>
            </div>
            <div className="col-12 col-md-6">
                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                <div className="row">
                <div className="col-10">
                <a href="/staking" style={{textDecoration:'none'}}>
                        <h3  style={{color:'black', textAlign:'left', fontSize:'21px'}}>                    Creating an ellaborate staking pool</h3>
                        </a>
                            </div>
                            <div className="col-2">
                            <img src="/rightArrow.png" width="23px" />
                            </div>
                        </div>
                        <Carousel infiniteLoop>
                            <div>
                                <img src="/s4.png" />
                                <p className="legend">Staking</p>
                            </div>
                            <div>
                                <img src="/s5.png" />
                                <p className="legend">Staking</p>
                            </div>
                            <div>
                                <img src="/s15.png" />
                            </div>
                        </Carousel>
                        <a href="/staking" style={{textDecoration:'none'}}>
                        <button className="btn btn-primary" style={{margin:'0 auto', display:'block'}}>
                            See more
                        </button>
                        </a>
                        {/* <p style={{color:'black', fontSize:'17px', marginTop:'8%'}}>
                        Want to build your own PancakeSwap or Uniswap fork? Or just a swap page for your token? We offer full flexibility.                        
                        </p> */}
                </div>
            </div>

            <div className="col-12 col-md-6">
                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                <div className="row">
                <div className="col-10">
                <a href="/custom-swap" style={{textDecoration:'none'}}>
                        <h3  style={{color:'black', textAlign:'left', fontSize:'21px'}}>                     Create a Swap</h3>
                        </a>
                            </div>
                            <div className="col-2">
                            <img src="/rightArrow.png" width="23px" />
                            </div>
                        </div>
                        <Carousel infiniteLoop>
                            <div>
                                <img src="/s3.png" />
                                <p className="legend">Dex</p>
                            </div>
                            <div>
                                <img src="/s2.png" />
                                <p className="legend">Full DeFi solution</p>
                            </div>
                            <div>
                                <img src="/s1.png" />
                                <p className="legend">Dex + Staking</p>
                            </div>
                            <div>
                                <img src="/s6.png" />
                                <p className="legend">Dex</p>
                            </div>
                            <div>
                                <img src="/s7.png" />
                                <p className="legend">Dex</p>
                            </div>
                        </Carousel>
                        <a href="/custom-swap" style={{textDecoration:'none'}}>
                        <button className="btn btn-primary" style={{margin:'0 auto', display:'block'}}>
                            See more
                        </button>
                        </a>
                </div>
                      </div>
                      <div className="col-12 col-md-6">
                    <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                        <div className="row">
                            <div className="col-10">
                            <a href="/staking" style={{textDecoration:'none'}}>
                            <h3  style={{color:'black', textAlign:'left', fontSize:'21px'}}>                   Creating a basic staking pool</h3>
                            </a>
                            </div>
                            <div className="col-2">
                            <img src="/rightArrow.png" width="23px" />
                            </div>
                        </div>
                        <Carousel infiniteLoop>
                            <div>
                                <img src="/s14.png" />
                                <p className="legend">Staking</p>
                            </div>
                            <div>
                                <img src="/s13.png" />
                                <p className="legend">Staking</p>
                            </div>
                            <div>
                                <img src="/s15.png" />
                            </div>
                        </Carousel>
                        <a href="/staking" style={{textDecoration:'none'}}>
                        <button className="btn btn-primary" style={{margin:'0 auto', display:'block'}}>
                            See more
                        </button>
                        </a>
                    </div>
            </div>

            <div className="col-12 col-md-6">

                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                <div className="row">
                <div className="col-10">
                <a href="https://mintstarter.app" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>

                        <h3  style={{color:'black', textAlign:'left', fontSize:'21px'}}>   Launch an NFT collection</h3>

</a>
                            </div>
                            <div className="col-2">
                            <img src="/rightArrow.png" width="23px" />
                            </div>
                        </div>
                        <Carousel infiniteLoop>
                            <div>
                                <img src="/s8.png" />
                                <p className="legend">Creator</p>
                            </div>
                            <div>
                                <img src="/s9.png" />
                                <p className="legend">Collection</p>
                            </div>
                            <div>
                                <img src="/s10.png" />
                                <p className="legend">Collection</p>
                            </div>
                        </Carousel>
                        <a href="https://mintstarter.app" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>
                        <button className="btn btn-primary" style={{margin:'0 auto', display:'block'}}>
                            See more
                        </button>
                        </a>
                </div>
            </div>
        </div>

        <div className="col-12">
            <h3 style={{textAlign:'left',   textDecoration: "underline",textDecorationColor: "orange"}}>Additional Utilities</h3>
            
            <div className="row">
                <div className="col-12 col-md-4">
                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                    <div className="row">
                    <div className="col-10">
                    <a href="/token-creator" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>

                            <h3  style={{color:'black', textAlign:'left', fontSize:'21px', textDecoration:'underline', textAlign:'center'}}>                     Create your own token</h3>

                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 col-md-4">
                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                    <div className="row">
                    <div className="col-10">
                    <a href="https://blockswapdex.netlify.app/swap?inputCurrency=ETH&outputCurrency=0x4636b326f19ca9b8a4c98f91672a31c617f249d3&slippage=1200&dark=dark" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>

                            <h3  style={{color:'black', textAlign:'left', fontSize:'21px', textDecoration:'underline', textAlign:'center'}}>  Trade with our Swap</h3>

                    </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-12 col-md-4">
                <div style={{background:"url('/bluething.png')",     backgroundSize: '50px',backgroundRepeat: 'no-repeat',padding: '25px'}}>
                    <div className="row">
                    <div className="col-10">
                    <a href="https://stake.blockswap.app/" target="_blank" rel="noreferrer" style={{textDecoration:'none'}}>

                            <h3  style={{color:'black', textAlign:'left', fontSize:'21px', textDecoration:'underline', textAlign:'center'}}> Stake your BLOCK tokens</h3>

                    </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            
            <br />
            
        </div>

    </div>


  ); 
}

export default MintstarterServices;