

import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    useWeb3React,
  } from "@web3-react/core";
import Web3 from 'web3'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import FACTORYABI from '../utils/staking_factory_abi.json'
import TOKENABI from '../utils/token_abi.json'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { ValidationForm, TextInput, FileInput, Radio  } from 'react-bootstrap4-form-validation'
import Icofont from "react-icofont";
const ethers = require('ethers');





function LaunchComponent() {
  


  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

    // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
      console.log('running')
      if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
      }
  }, [activatingConnector, connector]);



  const [stakingaddr, setStakingaddr] = React.useState('')
  const [rewardaddr, setRewardaddr] = React.useState('')
  const [start, setStart] = React.useState('')
  const [end, setEnd] = React.useState('')
  const [reward, setReward] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [deployed, setDeployed] = React.useState('')
  const [mypools, setMypools] = React.useState([])


  React.useEffect(async() => {
    if(account){
      const contractAddress = '0x56b159852AF9224320c3B6F3da86b8753b817DDd'
      const web3 = new Web3(library.provider)
      const contract = new web3.eth.Contract(FACTORYABI, contractAddress);
      const poolsCreated = await contract.methods.getMyPoolsLength(account).call() 
      const myPools = []
      for(let i=0; i<poolsCreated; i++){
        const poolAddress = await contract.methods.myPools(account, i).call()
        myPools.push(
          <div className="col-12">
            <a href={`/pool/${poolAddress}`} style={{textDecoration:'none', color:'black', textAlign:'center', fontWeight:'bold'}}>
            <div style={{background:'transparent'}}>
              <p style={{  padding:'15px', color:'black', borderRadius:'25px'}}>{poolAddress} <img src="/externalLink.png" width="15px" alt="external link" /> </p> 
            </div>
            </a>
          </div>
        )
      }
      setMypools(myPools)
    }
  }, [account])

  const deployPool = async() => {
      try{
        const contractAddress = '0x56b159852AF9224320c3B6F3da86b8753b817DDd'
        setLoading(true)
        const web3 = new Web3(library.provider)
        const contract = new web3.eth.Contract(FACTORYABI, contractAddress);
        const provider = new ethers.providers.StaticJsonRpcProvider('https://rpc.ankr.com/bsc');
        const currentBlock = await provider.getBlockNumber()
        const currentTime = parseFloat(Date.now()) / 1000
        let _startingTime = new Date(start) 
        _startingTime = _startingTime.getTime() / 1000
        let _endTime = new Date(end) 
        _endTime = _endTime.getTime() / 1000
        const blockTime = 3;
        const difference = Math.round(_startingTime - currentTime)
        let blocksToAdd = Math.round(difference /  blockTime)
        let initialBlock = currentBlock+blocksToAdd
        const differenceEnd = Math.round(_endTime - currentTime)
        let blocksToAddEnd = Math.round(differenceEnd / blockTime)
        let finalBlock = currentBlock + blocksToAddEnd
        const diffBlocksEndStart = finalBlock - initialBlock;
        const rewardPerBlock = reward / diffBlocksEndStart;
        console.log(initialBlock, finalBlock, rewardPerBlock)

        const tokencontract = new web3.eth.Contract(TOKENABI, rewardaddr);
        const _decimalsReward = await tokencontract.methods.decimals().call() 
        const _rewardPerBlock = toFixed(Math.round(rewardPerBlock * 10 ** _decimalsReward))
        const BNBprice = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
        const priceToPay = 800 / BNBprice.data['binancecoin'].usd;
        const realPrice = priceToPay * 10 ** 18

        const x = await contract.methods.deployPool(stakingaddr, rewardaddr, _rewardPerBlock.toString(), initialBlock, finalBlock, '0','0xe3C601b1FC6564ebf5603fCaD7956697761E39Db').send({
          from: account,
          value: realPrice
        })
        console.log(x)
        setDeployed(x.events.NewSmartChefContract.returnValues['smartChef'])
        setLoading(false)
      }catch(err){
          setLoading(false)
          console.log(err)
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
  }

  function toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }

  return (
    <div className="row" style={{marginTop:'10%', marginBottom:'5%'}}>
                            <div className="alert alert-primary" style={{width:"100%", background:'#4742FF', color:'white', border:'0px'}}>
                                <p>
                                You will need to deposit reward tokens in the pool so it has from where to reward users. If your token is deflationary (it has transfer taxes), you will need to exclude the pool address from fees after deployment.
                                </p>
                            </div>
                            <div className="card" style={{width:"100%", color:'black', borderRadius:'22px', boxShadow:'0 5.40728px 10.8146px rgba(0,0,0,.3)'}}>
          <div className="card-header" style={{background:'#4742FF', borderRadius:'22px 22px 0px 0px'}}>
          <h5 style={{textAlign:'left', fontWeight:'bold', color:'white'}}>
                Entry Package Form
                </h5>
          </div>            <div className="card-body">
          <label htmlFor="tokenSymbol" style={{color:'black'}}>Staking Token address</label>
                                <input name="tokenSymbol" id="UNIv2" required
                                 placeholder="0x123..."
                                 onChange={(event) => setStakingaddr(event.target.value)}
                                 style={{color:'black', width:'100%', border:'1px solid #191D26', borderRadius:'25px', padding:'8px'}}
                                    // value={firstName}
                                    // onChange={this.handleChange}
                                />  
                                <label htmlFor="tokenSymbol" style={{color:'black', marginTop:"1%"}}>Reward Token address</label>
                                <input name="tokenSymbol" id="UNIv2" required
                                 placeholder="0x123..."
                                 onChange={(event) => setRewardaddr(event.target.value)}
                                 style={{color:'black', width:'100%', border:'1px solid #191D26', borderRadius:'25px', padding:'8px'}}
                                />  

                                <label htmlFor="tokenSymbol" style={{color:'black', marginTop:"1%"}}>Preferred Starting Time</label>
                                <input name="tokenSymbol" id="UNIv2" required
                                 type="datetime-local"
                                 style={{color:'black', width:'100%',  border:'1px solid #191D26', borderRadius:'25px', padding:'8px'                               }}
                                 onChange={(event) => setStart(event.target.value)}
                                />

                                <label htmlFor="tokenSymbol" style={{color:'black', marginTop:"1%"}}>Preferred Ending Time</label>
                                <input name="tokenSymbol" id="UNIv2" required
                                 type="datetime-local"
                                 style={{color:'black', width:'100%',  border:'1px solid #191D26', borderRadius:'25px', padding:'8px'}}
                                 onChange={(event) => setEnd(event.target.value)}
                                />

                                <label htmlFor="tokenSymbol" style={{color:'black', marginTop:"1%"}}>How many reward tokens will be rewarded by this pool</label>
                                <input name="tokenSymbol" id="UNIv2" required
                                 type="number"
                                 placeholder="100000"
                                 style={{color:'black', width:'100%',  border:'1px solid #191D26', borderRadius:'25px', padding:'8px'}}
                                 onChange={(event) => setReward(event.target.value)}
                                />
                                                <p style={{textAlign:'left', color:'grey', fontWeight:'bold', marginTop:'3%'}}>
                     Price to Pay (deployment costs included)
                </p>
                <ul>
                    <li className="customli">$800 in BNB + gas fees</li>
                </ul>
                                <button className="btn btn-primary" style={{borderRadius:'25px', background:'#4742FF',color:'white', width:'100%'}} onClick={deployPool} disabled={loading} >
                                  {loading ? <div class="spinner-border text-light" role="status">
</div> : 'Deploy Staking Pool'}
                                 
                                   </button>
                                   {deployed !== '' ?                                    <div className="alert alert-primary" style={{width:"100%", marginTop:'3%'}}>
                                     <h4 >Staking Pool Deployed </h4>
                                <p>
                                    The pool has been deployed at {deployed} .
                                    Remember to deposit {reward} of your reward token to {deployed} (the deployed pool address). 
                                    APR/APY may not show until you make this deposit. If your token has taxes (e.g transfer tax), exclude the pool address from taxes. 
                                </p>
                            </div> : null}

                </div>
                </div>
                                      

    {/* TOAST AREA */}
    <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />

  {mypools.length !== 0 ? <div className="card" style={{width:'100%', marginTop:'3%'}}> 
  <div className="card-body">
  <h4 style={{color:'black'}}>My Pools</h4>

<div className="row" style={{width:'100%'}}>
  {mypools}
</div> 
</div>
</div> : null}

    </div>


  ); 
}

export default LaunchComponent;