

import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    useWeb3React,
  } from "@web3-react/core";
import ABI from '../utils/token2_abi.json'
import BYTECODE from '../utils/token2_bytecode.json'
import Web3 from 'web3'


function TokenCreator() {
  

  const [name, setName] = React.useState('')
  const [symbol, setSymbol] = React.useState('')
  const [decimals, setDecimals] = React.useState('')
  const [supply, setSupply] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [isdeployed, setIsdeployed] = React.useState(false)
  const [tokenaddress, setTokenaddress] = React.useState('')

  
  const context = useWeb3React();
  const {
    library,
    chainId,
    account,
    active,
  } = context;



  function toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }
  

  const deployToken = async() => {
      try{
        setLoading(true)
        if(account && (chainId == 56)){
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI);
            const amount = supply * 10 ** decimals;
            const amountToPay = '1200000000000000000'
            console.log(toFixed(amount))
            let x = await contract.deploy({
                data: BYTECODE.object,
                arguments: [name.toString(), symbol.toString(), decimals.toString(), toFixed(amount)]
                  }).send({
                    from: account,
                    value: amountToPay
                  })
            setLoading(false)
            setIsdeployed(true)
            setTokenaddress(x._address)
      }
      }catch(err){
        alert("something went wrong deploying your token")
        console.log(err)
        setLoading(false)
      }

  }

  return (
    <div className="row" style={{marginTop:'10%', marginBottom:'5%'}}>
        <div className="card" style={{width:"100%", color:'black', borderRadius:'22px', boxShadow:'0 5.40728px 10.8146px rgba(0,0,0,.3)'}}>
          <div className="card-header" style={{background:'#4742FF', borderRadius:'22px 22px 0px 0px'}}>
          <h5 style={{textAlign:'left', fontWeight:'bold', color:'white'}}>
                Token Creator
                </h5>
          </div>
            <div className="card-body">
                <p style={{textAlign:'left', color:'black'}}>
                We bring for you the best BEP20/ERC20 token creator on the market.
With our automated factory you will be able to create your own token on blockchain at the speed of the click of a button.                </p>
                <h5 style={{textAlign:'left', color:'black', fontWeight:'bold', marginTop:'5%'}}>
                    Token Specs
                </h5>
                <ul style={{padding:'0'}}>
                    <li className="customli">Fully BEP20/ERC20 compliant</li>
                    <li className="customli">Super Secure</li>
                    <li className="customli">No MINT function</li>
                    <li className="customli">Decentralized</li>
                    <li className="customli">Alien-tech for releasing tokens (see below)</li>
                </ul>
                <h5 style={{textAlign:'left', color:'black', fontWeight:'bold', marginTop:'5%'}}>
                    Service Price
                </h5>
                <ul style={{padding:'0'}}>
                    <li className="customli">1.2 BNB</li>
                </ul>
                <div style={{marginTop:'5%'}}>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Token Name</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" style={{ color:'black', border:'1px solid #191D26', borderRadius:'25px'}} placeholder="BlockSwap Finance" value={name} onChange={(event) => setName(event.target.value)} />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Token Symbol (No spaces)</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" style={{ color:'black', border:'1px solid #191D26', borderRadius:'25px'}} placeholder="BlockSwap" value={symbol} onChange={(event) => setSymbol(event.target.value)} />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Decimals</label>
                    <input type="number" class="form-control" id="exampleFormControlInput1" style={{ color:'black', border:'1px solid #191D26', borderRadius:'25px'}} placeholder="18(recommended)"  value={decimals} onChange={(event) => setDecimals(event.target.value)} />
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Total Supply</label>
                    <input type="number" class="form-control" id="exampleFormControlInput1" style={{ color:'black', border:'1px solid #191D26', borderRadius:'25px'}}  placeholder="100000000" value={supply} onChange={(event) => setSupply(event.target.value)} />
                </div>
                {account && active ? <div> 
                    <button class="btn btn-outline-primary" onClick={deployToken} disabled={loading}  style={{borderRadius:'25px', background:'#4742FF',color:'white', width:'100%'}} type="button"> {loading ? <div class="spinner-border text-light" role="status">
                    </div> : <><span> Deploy on</span> {chainId == 56 ? "Binance Smart Chain" : 'undefined network'} </>} </button>   
                 </div> : <button class="btn btn-outline-primary"  data-toggle="modal" data-target="#exampleModal" style={{borderRadius:'25px', background:'#4742FF',color:'white', width:'100%'}} type="button"> <span> Connect Wallet</span></button>}
                </div>
                <div>
                    {isdeployed ? <div class="alert alert-primary" style={{marginTop:'3%'}} role="alert">
                        <p>Your token has been deployed at <span style={{fontWeight:'bold'}}>{tokenaddress}</span> . Save this address for reference purposes.</p>
                        <p> <a href={`https://bscscan.com/address/${tokenaddress}`} target="_blank" rel="noreferrer">View on BscScan</a></p>
                         </div> : null}
                </div>
        
            </div>        

        </div>
        <div style={{marginTop:'3%'}}>
          <h4 style={{color:'black'}}>Note </h4>
            <p style={{color:'black'}}>
                9/10 token deployment services release tokens via a smart contract. We've engineered the entire system to provide you with an industry-leading token deployer done directly from your wallet. 
                There are hundreds of benefits when doing that, including <span style={{fontWeight:'bold'}}>ease of verifying token on BscScan/Etherscan</span>, <span style={{fontWeight:'bold'}}>improved perceived professionalism of your project / token </span>, 
                and <span style={{fontWeight:'bold'}}>a closer token to how professional developers release them.</span>
                </p>

                <p style={{color:'black'}}>
                For more complex contracts to be built from scratch from our in house developer, message <a href="https://t.me/takethered2" style={{color:'cornflowerblue'}} target="_blank" rel="noreferrer"> @TakeTheRed2 </a> with the key word ‘ Complex Contract ‘
                </p>
            </div>

    </div>


  ); 
}

export default TokenCreator;